import { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";

const Frame = (props) => {
    const { className = '', overrides, isFrame1Open, onClose, handleContinue, handleSearchclick, alertpopup, handleAddressreset, areasMarked, addressvalue, defaultaddress, navigateToDashboard, currentProperty, ...rest } = props;
    const [address, setAddress] = useState();
    useEffect(() => {
        addressvalue ? setAddress(currentProperty) : setAddress(defaultaddress);
    }, [addressvalue, currentProperty, defaultaddress]);

    if (!isFrame1Open) {
        return null;
    }
    const formattedAddress = addressvalue
        ? address?.freeformAddress?.replace(/"/g, '')
        : `${address?.enityMailingAdderess}, ${address?.cityId}, ${address?.zipcode}`;

    return (
        <div className={`confirm-property-location-parent ${className}`}>
            {
                props.isDashboard && !props.isAddressSelected ?
                    <div>
                        <b className="confirm-property-location1">Are you sure want to go back to Dashboard? </b>
                        <div className="yes-continue-wrapper" onClick={navigateToDashboard}>
                            <b className="yes-continue">Yes, Go Back</b>
                        </div>
                        <div className="no-go-back-wrapper" onClick={onClose}>
                            <b className="yes-continue">No, Stay Here</b>
                        </div>
                    </div> :
                    props.isAddressSelected && !alertpopup && areasMarked.length === 0 ? (
                        <div>
                            <b className="confirm-property-location">Confirm Property Location</b>
                            <div className='address'>{`Current property address: ${formattedAddress}`}<br /></div>
                            {/* <div className="before-proceeding-please">Before proceeding, please ensure that the property you have located on the map is correct.</div> */}
                            <div className="are-you-sure">Are you sure you want to go to the next step?</div>
                            <div className="yes-continue-wrapper" onClick={handleContinue}>
                                <b className="yes-continue">Yes, Continue</b>
                            </div>
                            <div className="no-go-back-wrapper" onClick={onClose}>
                                <b className="yes-continue">No, Go Back</b>
                            </div>
                        </div>
                    ) : (
                        alertpopup ? (
                            <div>
                                <b className="confirm-property-location1">Are you sure you want change the address? </b>
                                <div className="yes-continue-wrapper" onClick={handleAddressreset}>
                                    <b className="yes-continue">Yes, Go Back</b>
                                </div>
                                <div className="no-go-back-wrapper" onClick={onClose}>
                                    <b className="yes-continue">No, Stay Here</b>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <b className="confirm-property-location">Are you sure you want to proceed ? </b>
                                <div className="before-proceeding-please">Don't worry! You can change the parking lot markers at any time from your dashboard.</div>
                                {/* <div className="are-you-sure">Are you sure you want to go to the next step?</div> */}
                                <div className="yes-continue-wrapper" onClick={handleContinue}>
                                    <b className="yes-continue">Yes, Continue</b>
                                </div>
                                <div className="no-go-back-wrapper" onClick={onClose}>
                                    <b className="yes-continue">No, Go Back</b>
                                </div>
                            </div>
                        )
                    )}
        </div>
    );
};

Frame.propTypes = {
    className: PropTypes.string,
};

export default Frame;
