import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './VerifyAlert.css'
import axios from 'axios';
import FadeLoader from 'react-spinners/FadeLoader'; // Import FadeLoader


const VerifyAlert = (props) => {
    const { handleShouldapprove, isPopupVisible, shouldApprove,BankDetails,Scheduleavailability, CoardinateDetails,searchedLocation,streetName, ...rest } = props;
    const [comment, setComment] = useState('');
    const [error, setError] = useState(''); // State for error message
    const [isLoading, setIsLoading] = useState(false); // State for loading spinner
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const message = shouldApprove
        ? "Are you sure you want to approve?"
        : "Are you sure you want to reject?";

    const handleAcceptReject = (e,val) => {
        e.preventDefault();
        if (comment.trim() === '') {
            setError('Comment cannot be empty');
            return;
        }
       else if (props.parkinglotObj) {
            setError('');
            setIsButtonDisabled(true);
            setIsLoading(true); // Show spinner when request starts
            const parkinglot = {
                "EmailId": props.parkinglotObj.emailId,
                "EntityRegistrationID": props.parkinglotObj.entityRegistrationID,
                "ParkingLotAddress": searchedLocation,
                "Parking_Lot_ID": props.parkinglotObj.parking_Lot_ID,
                "id": props.parkinglotObj.id,
                "CreatedDate": props.parkinglotObj.createdDate,
                "Status": val? "Approved":"Rejected",
                "Comment":comment,
                "StreetName":streetName,
                BankDetails,
                CoardinateDetails,
                Scheduleavailability
            };
            BankDetails.EntityRegistrationID = props.entityId;
            axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
                headers: {
                    "x-functions-key": hostHeader
                }
            })
            axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
                headers: {
                    "x-functions-key": hostHeader
                }
            })
            .then(response => {
                    if (response.data) {
                        e.preventDefault();
                        setIsLoading(false); // Hide spinner if error occurs
                        handleShouldapprove(false, false);
                        navigate('/myworklist')
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsLoading(false); // Hide spinner if error occurs
                    setIsButtonDisabled(false);
                });
        }
    };
    return (
        <div>
            {isLoading && ( // Show spinner overlay while loading
                <div className="loader-overlay">
                    <FadeLoader/>
                </div>
            )}

            {isPopupVisible && !isLoading && ( // Show alert only if not loading
                <div className="alert-overlay">
                    <div className="alert">
                        <p>{message}</p>
                        <textarea
                            placeholder="Add your comments here"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        {error && <p className="error-message">{error}</p>} {/* Display error message */}
                        <div className="button-container">
                            <button
                                onClick={(e) => handleAcceptReject(e, shouldApprove)}
                                className={shouldApprove ? 'accept-button' : 'reject-button'}
                                disabled={isButtonDisabled}
                            >
                                {shouldApprove ? "Accept" : "Reject"}
                            </button>
                            <button onClick={() => handleShouldapprove(false, false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyAlert;