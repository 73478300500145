import React, { useState, useEffect } from 'react';
import './AvailabilityModal.css';
import ClockIcon from './assets/Layer_1.svg';
import SmallClock from './assets/Capa_1.svg';
import ArrowIcon from './assets/Arrow 2.svg';
import RecurrenceIcon from './assets/Layer_2.svg';
import CalendarIcon from './assets/CalendarIcon.svg';

// Custom function to format date to 'YYYY-MM-DD'
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// Custom function to format time to 'HH:MM'
const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

// Calculate duration between start and end times
const calculateDuration = (start, end) => {
    if (start && end) {
        const startDate = new Date(`1970-01-01T${start}`);
        const endDate = new Date(`1970-01-01T${end}`);
        const diff = (endDate - startDate) / (1000 * 60); // difference in minutes

        if (diff >= 0) {
            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;
            return `${hours}h ${minutes}min`;
        }
    }
    return '';
};

const AvailabilityModal = ({ show, onClose, onSave, onDelete, isEditing, eventDetails }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [recurrence, setRecurrence] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [duration, setDuration] = useState('');
    const [allDay, setAllDay] = useState(false);
    const [customRecurrenceType, setCustomRecurrenceType] = useState('week');
    const [customRecurrenceCount, setCustomRecurrenceCount] = useState(1);
    const [endType, setEndType] = useState('never'); // 'never', 'on', 'after'
    const [endDateOn, setEndDateOn] = useState('');
    const [endAfter, setEndAfter] = useState(1);
    const [isHoliday, setIsHoliday] = useState(false);

    useEffect(() => {
        if (eventDetails && show) {
            const startDateObj = eventDetails.start ? new Date(eventDetails.start) : null;
            const endDateObj = eventDetails.end ? new Date(eventDetails.end) : null;

            if (startDateObj && !isNaN(startDateObj.getTime())) {
                setStartDate(formatDate(startDateObj));
                setStartTime(formatTime(startDateObj));
            }

            if (endDateObj && !isNaN(endDateObj.getTime())) {
                setEndDate(formatDate(endDateObj));
                setEndTime(formatTime(endDateObj));
            }

            setRecurrence(eventDetails.recurrence || null);
            setSelectedDays(eventDetails.selectedDays || []);
            setAllDay(eventDetails.allDay || false);
            setCustomRecurrenceType(eventDetails.customRecurrenceType || 'week');
            setCustomRecurrenceCount(eventDetails.customRecurrenceCount || 1);
            setEndType(eventDetails.endType || 'never');
            setEndDateOn(eventDetails.endDateOn ? formatDate(eventDetails.endDateOn) : '');
            setEndAfter(eventDetails.endAfter || 1);
            setIsHoliday(eventDetails.isHoliday || false);
        }

        console.log("Modal opened, initial event details:", eventDetails);
        console.log("Modal opened, initial state:", {
            startDate, endDate, startTime, endTime, recurrence, selectedDays, duration, allDay, customRecurrenceType, customRecurrenceCount, endType, endDateOn, endAfter, isHoliday
        });
    }, [eventDetails, show]);

    // Ensure 'Never' defaults to 1 year later if selected
    useEffect(() => {
        console.log('EndType changed to:', endType);
        if (endType === 'never' && startDate) {
            const oneYearLater = new Date(startDate);
            oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
            setEndDateOn(formatDate(oneYearLater));
            console.log('Setting end date to 1 year later:', formatDate(oneYearLater));
            
            // Ensure endAfter is cleared when 'never' is selected
            setEndAfter(null);  // No occurrences if "never" is selected
            console.log('Clearing endAfter, set to null as endType is never');
        }
    }, [endType, startDate]);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const handleStartTimeChange = (event) => {
        const newStartTime = event.target.value;
        setStartTime(newStartTime);
        updateEndDateAndTime(startDate, newStartTime, endTime);
    };

    const handleEndTimeChange = (event) => {
        const newEndTime = event.target.value;
        setEndTime(newEndTime);
        updateEndDateAndTime(startDate, startTime, newEndTime);
    };

    const updateEndDateAndTime = (startDate, startTime, endTime) => {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        let endDateTime = new Date(`${startDate}T${endTime}`);

        if (endDateTime.getTime() <= startDateTime.getTime()) {
            endDateTime.setDate(endDateTime.getDate() + 1);
            setEndDate(formatDate(endDateTime));
        } else {
            setEndDate(startDate);
        }

        setDuration(calculateDuration(startTime, endTime));
    };

    const handleRecurrenceChange = (e) => {
        setRecurrence(e.target.id);
        console.log('Recurrence type changed to:', e.target.id);
        if (e.target.id !== 'custom') {
            setSelectedDays([]);
        }
    };

    const handleDayClick = (day) => {
        if (recurrence === 'custom') {
            setSelectedDays((prevDays) =>
                prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
            );
        }
        console.log("Selected days after click: ", selectedDays); // Debugging log to check the selected days
    };
    
    const handleSave = () => {
        console.log("Saving event, selectedDays: ", selectedDays); // Debug log to check selectedDays when saving
        if (!startDate || !endDate || (!allDay && (!startTime || !endTime))) {
            alert("Please enter valid date and time.");
            return;
        }
    
        const startDateTime = allDay ? new Date(startDate) : new Date(`${startDate}T${startTime}`);
        const endDateTime = allDay ? new Date(endDate) : new Date(`${startDate}T${endTime}`);
    
        if (isNaN(startDateTime) || isNaN(endDateTime)) {
            alert("Invalid date or time. Please check your inputs.");
            return;
        }
    
        if (startDateTime >= endDateTime) {
            alert("End time must be later than start time.");
            return;
        }
    
        const newEvent = {
            title: 'Open Hours',
            start: startDateTime,
            end: endDateTime,
            recurrence,
            allDay,
            selectedDays, // Ensure selectedDays is passed here
            customRecurrenceType,
            customRecurrenceCount,
            endType,
            endDateOn: endDateOn ? new Date(endDateOn) : null,
            endAfter,
            isHoliday
        };
    
        const recurrenceChanged = isEditing && eventDetails.recurrence !== recurrence;
        console.log("Event being saved: ", newEvent); // Debugging log to inspect the saved event
        onSave(newEvent, recurrenceChanged);
    };

    const handleDeleteEvent = (deleteSeries) => {
        onDelete(eventDetails, deleteSeries);
    };

    if (!show) return null;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <div className="custom-modal-header">
                    <div className="custom-modal-title-wrapper">
                        <img src={ClockIcon} alt="Clock Icon" className="custom-clock-icon" />
                        <div>
                            <h2 className="custom-modal-title">{isEditing ? 'Edit Availability' : 'Save Availability'}</h2>
                            <p className="custom-modal-subtitle">Confirm the selected time slots for parking space availability.</p>
                        </div>
                    </div>
                    <button onClick={onClose} className="custom-close-button">×</button>
                </div>
                <div className="custom-modal-body">
                    {/* Date Inputs */}
                    <div className="custom-datetime-group">
                        <div className="custom-calendar-icon-wrapper">
                            <img src={CalendarIcon} alt="Calendar Icon" className="custom-calendar-icon" />
                        </div>
                        <div className="custom-date-group">
                            <div className="custom-input-container">
                                <input type="date" className="custom-input" value={startDate} onChange={handleStartDateChange} />
                            </div>
                        </div>
                    </div>
                    {/* Time Inputs */}
                    <div className="custom-datetime-group">
                        <img src={SmallClock} alt="Clock Icon" className="custom-datetime-icon" />
                        <div className="custom-time-group">
                            <div className="custom-input-container">
                                <input type="time" className="custom-input" value={startTime} onChange={handleStartTimeChange} />
                            </div>
                            <div className="custom-arrow-container">
                                <img src={ArrowIcon} alt="Arrow Icon" className="custom-arrow-icon" />
                            </div>
                            <div className="custom-input-container">
                                <input type="time" className="custom-input" value={endTime} onChange={handleEndTimeChange} />
                            </div>
                            <span className="custom-duration-text">{duration}</span>
                        </div>
                    </div>
                    {/* Recurrence Options */}
                    <div className="custom-recurrence-icon-wrapper">
                        <img src={RecurrenceIcon} alt="Recurrence Icon" className="custom-recurrence-icon" />
                    </div>
                    <div className="custom-recurrence-options">
                        <div className="custom-recurrence-option">
                            <input type="radio" id="daily" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'daily'} />
                            <label htmlFor="daily">Daily</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="weekly" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'weekly'} />
                            <label htmlFor="weekly">Weekly on the Same Day</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="bi-weekly" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'bi-weekly'} />
                            <label htmlFor="bi-weekly">Bi-Weekly</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="monthly" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'monthly'} />
                            <label htmlFor="monthly">Monthly on the Same Date</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="yearly" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'yearly'} />
                            <label htmlFor="yearly">Yearly on the Same Date</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="weekdays" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'weekdays'} />
                            <label htmlFor="weekdays">Every Weekdays Mon-Fri</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="weekends" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'weekends'} />
                            <label htmlFor="weekends">Every Weekends Sat-Sun</label>
                        </div>
                        <div className="custom-recurrence-option">
                            <input type="radio" id="custom" name="recurrence" onChange={handleRecurrenceChange} checked={recurrence === 'custom'} />
                            <label htmlFor="custom">Custom</label>
                        </div>
                    </div>
                    {/* Custom Recurrence Options */}
                    {recurrence === 'custom' && (
                        <div className="custom-recurrence-custom">
                            <div className="custom-recurrence-custom-every">
                                <label htmlFor="customRecurrenceCount">Every</label>
                                <input
                                    type="number"
                                    id="customRecurrenceCount"
                                    min="1"
                                    value={customRecurrenceCount}
                                    onChange={(e) => setCustomRecurrenceCount(e.target.value)}
                                    className="custom-recurrence-input"
                                />
                                <select
                                    id="customRecurrenceType"
                                    value={customRecurrenceType}
                                    onChange={(e) => setCustomRecurrenceType(e.target.value)}
                                    className="custom-recurrence-select"
                                >
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                </select>
                            </div>
                            {customRecurrenceType === 'week' && (
                                <div className="custom-weekdays-group">
                                    <div className="custom-weekdays-options">
                                        <p className=" custom-weekdays-options" style={{textAlign:'center', paddingBottom:'45px'}} >On</p>
                                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                                            <button
                                                key={day}
                                                className={`custom-weekday-button ${selectedDays.includes(day) ? 'selected' : ''}`}
                                                onClick={() => handleDayClick(day)}
                                            >
                                                {day}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {/* Recurrence End Options - Now Always Visible */}
                    <div className="custom-recurrence-end">
                        <p className="custom-recurrence-custom-every" style={{ textAlign: 'left' }}>Ends</p>
                        <div className="custom-recurrence-end-options">
                            <div className="custom-recurrence-end-option">
                                <input
                                    type="radio"
                                    id="endNever"
                                    name="endType"
                                    checked={endType === 'never'}
                                    onChange={() => setEndType('never')}
                                    className="custom-recurrence-radio"
                                />
                                <label htmlFor="endNever">Never</label>
                            </div>
                            <div className="custom-recurrence-end-option">
                                <input
                                    type="radio"
                                    id="endOn"
                                    name="endType"
                                    checked={endType === 'on'}
                                    onChange={() => setEndType('on')}
                                    className="custom-recurrence-radio"
                                />
                                <label htmlFor="endOn">On</label>
                                <div className="custom-input-container"> {/* Container for styling */}
                                    <input
                                        type="date"
                                        id="endDateOn"
                                        value={endDateOn}
                                        onChange={(e) => setEndDateOn(e.target.value)}
                                        disabled={endType !== 'on'}
                                        className="custom-input"
                                    />
                                </div>
                            </div>
                            <div className="custom-recurrence-end-option">
                                <input
                                    type="radio"
                                    id="endAfter"
                                    name="endType"
                                    checked={endType === 'after'}
                                    onChange={() => setEndType('after')}
                                    className="custom-recurrence-radio"
                                />
                                <label htmlFor="endAfter">After</label>
                                <div className="custom-input-container custom-input-container-small"> {/* Smaller container */}
                                    <input
                                        type="number"
                                        id="endAfterCount"
                                        min="1"
                                        value={endAfter}
                                        onChange={(e) => setEndAfter(e.target.value)}
                                        disabled={endType !== 'after'}
                                        className="custom-input custom-recurrence-end-input"
                                    />
                                </div>
                                <span>times</span>
                            </div>
                        </div>
                    </div>
                    <div className="custom-recurrence-holiday">
                        <input
                            type="checkbox"
                            id="markAsHoliday"
                            checked={isHoliday}
                            onChange={() => setIsHoliday(!isHoliday)}
                        />
                        <label htmlFor="markAsHoliday">Mark this as a holiday</label>
                    </div>
                </div>
                <div className="custom-modal-footer">
                    {/* Show delete buttons when editing an event */}
                    {isEditing ? (
                        <>
                            <button className="custom-button custom-button-delete" onClick={() => handleDeleteEvent(true)}>Delete All</button>
                            <button className="custom-button custom-button-delete" onClick={() => handleDeleteEvent(false)}>Delete</button>
                        </>
                    ) : (
                        <button className="custom-button custom-button-primary" onClick={handleSave}>Confirm</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AvailabilityModal;
