import React, { useState, useEffect, useRef } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import './CalendarComponent.css';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarComponent = ({ events, onSelectEvent, onAddEvent, onOpenModal }) => {
  const [allEvents, setAllEvents] = useState([]);
  const [view, setView] = useState('month'); // Default view
  const [selectedDate, setSelectedDate] = useState(new Date()); // State to track selected date
  const calendarRef = useRef(null); // Create a ref for the calendar

  useEffect(() => {
    // Ensure all events have valid data (title, start, end)
    const validatedEvents = events
      .filter(event => event && event.start && event.end) // Filter out invalid events
      .map(event => ({
        ...event,
        title: event.title || 'No Title', // Add a default title if missing
      }));

    setAllEvents(validatedEvents);
    console.log('CalendarComponent: Validated events', validatedEvents);
  }, [events]);

  const handleSelectSlot = ({ start }) => {
    console.log('CalendarComponent: Slot selected', start);
    onAddEvent(start);
  };

  const handleEventClick = (event) => {
    console.log('CalendarComponent: Event clicked', event);
    onSelectEvent(event);
  };

  const handleViewChange = (newView) => {
    console.log('CalendarComponent: Changing view to', newView);
    setView(newView); // Ensure the view state is updated
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate); // Update the selected date

    // Use the `onNavigate` to programmatically change the calendar date
    if (calendarRef.current && calendarRef.current.props.onNavigate) {
      calendarRef.current.props.onNavigate(newDate);
    }
  };

  const handleGoToToday = () => {
    const today = new Date();
    setSelectedDate(today);
    if (calendarRef.current && calendarRef.current.props.onNavigate) {
      calendarRef.current.props.onNavigate(today);
    }
  };

  return (
    <div className="calendar-wrapper">
  <div>
    <div className="datepicker-container">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={selectedDate}
          onChange={handleDateChange} // Handle date change here
        />
      </LocalizationProvider>
    </div>
    <div className="buttons-container">
  <button className="custom-button-primary" onClick={onOpenModal}>
    Create Event
  </button>
</div>

  </div>
  <div className="calendar-container">
    <Calendar
      ref={calendarRef} // Attach the ref to the Calendar component
      localizer={localizer}
      events={allEvents} // Use the validated events
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      selectable
      onSelectEvent={handleEventClick}
      onSelectSlot={handleSelectSlot}
      views={['month', 'week', 'day']} // Removed "year"
      view={view} // Controlled view state
      onView={handleViewChange}
      onNavigate={(date) => setSelectedDate(date)} // Track the selected date
      components={{
        toolbar: (toolbarProps) => (
          <CustomToolbar {...toolbarProps} onViewChange={handleViewChange} />
        ),
        event: EventComponent,
      }}
    />
  </div>
</div>

  );
};

const EventComponent = ({ event }) => {
  if (!event) {
    return null; // If the event is undefined, return nothing
  }

  const startTime = event.start ? format(event.start, 'hh:mm aa') : 'N/A';
  const endTime = event.end ? format(event.end, 'hh:mm aa') : 'N/A';

  return (
    <span>
      {event.title || 'No Title'} ({startTime} - {endTime})
    </span>
  );
};

const CustomToolbar = ({ onNavigate, onViewChange, label }) => {
  const goToBack = () => {
    console.log("Navigating to previous");
    onNavigate('PREV');
  };

  const goToNext = () => {
    console.log("Navigating to next");
    onNavigate('NEXT');
  };

  const handleViewChange = (view) => {
    console.log(`Changing view to ${view}`);
    onViewChange(view);
  };

  return (
    <div className="toolbar-container">
      <div className="toolbar-left">
        <span className="toolbar-title">{label}</span>
        <button className="toolbar-button today-button" onClick={() => onNavigate('TODAY')}>
          Today
        </button>
      </div>
      <div className="toolbar-right">
        <div onClick={goToBack} className="toolbar-svg-container" style={{marginBottom: '10px'}}>
          <ArrowLeftSVG />
        </div>
        <div onClick={goToNext} className="toolbar-svg-container" style={{marginBottom: '10px'}}>
          <ArrowRightSVG />
        </div>
        <button
          className={`toolbar-button ${label === 'month' ? 'active' : ''}`}
          onClick={() => handleViewChange('month')} style={{marginBottom: '10px'}}
        >
          Month
        </button>
        <button
          className={`toolbar-button ${label === 'week' ? 'active' : ''}`}
          onClick={() => handleViewChange('week')} style={{marginBottom: '10px'}}
        >
          Week
        </button>
        <button
          className={`toolbar-button ${label === 'day' ? 'active' : ''}`}
          onClick={() => handleViewChange('day')} style={{marginBottom: '10px'}}
        >
          Day
        </button>
        
      </div>
    </div>
  );
};


const ArrowLeftSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 19l-7-7 7-7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowRightSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5l7 7-7 7" stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CalendarComponent;
