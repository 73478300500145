import React, { useState, useEffect, useRef } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import Navbar from "../NavBar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./SettingEntityInfo.css"; // Import the CSS file
import SignUP from "../login/signUP";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhoneInput from 'react-phone-number-input';
import './SettingEntityInfo.css';

const SettingEntityInfo = () => {
  const navigate = useNavigate();
  const [CurrentPassword, setCurrentPassword] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
    notGuessable: false,
  });
  const [passworderrors, setPasswordErrors] = useState({});
  const [mapvisible, setMapvisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { section } = useParams();
  const [activeSection, setActiveSection] = useState(section || "entity");
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [profileChange, setProfilechange] = useState(false);
  const [verificationRequired, setVerificationrequired] = useState("");
  const [personalData, setPersonalData] = useState({});
  const [showdialogue, setShowdialogue] = useState(false);
  const [verificationenabled, setVerificationenabled] = useState(false);
  const [radiobuttonval, setRadiobuttonval] = useState("");
  const [oldMobilenum, setOldMobilenum] = useState();
  const [errors, setErrors] = useState({});
  const [EmaiErr, setEmaiErr] = useState(false);
  const [phoneData, setPhoneData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [mailingAddressZipCode, setMailingAddressZipCode] = useState("");
  const [zipcodeWarning, setZipcodeWarning] = useState("");
  const [mailingAddressCity, setMailingAddressCity] = useState("");
  const [cities, setCities] = useState([]);
  const [stateLogedInUser, setStateLogedInUser] = useState();
  const radiobuttonoption = useRef();
  const [states, setStates] = useState([]);
  var personaldata = {};
  const [formData, setFormData] = useState({
    EntityName: "",
    EnityMailingAdderess: "",
    EnityMailingAdderess2: "",
    Country: "United States",
    CityId: "",
    Zipcode: "",
    StateId: "",
    EmailId: "",
    //title: "",
  });
  const [profileData, setProfileData] = useState({
    FirstName: "",
    LastName: "",
    EmailId: "",
    MobileNumber: "",
    OfficeNumber: "",
    NewEmailId: "",
  });

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const [showDialog, setShowDialog] = useState(false);

  function getLoggedInUser() {
    const loggedInUser = localStorage.getItem('LogedInUser');
    if (!loggedInUser) {
      console.error('No LoggedInUser found');
      return null;
    }
    try {
      const stateLoggedInUser = JSON.parse(loggedInUser);
      return stateLoggedInUser;
    } catch (error) {
      console.error('Error parsing LoggedInUser', error);
      return null;
    }
  }
  const entityId = getLoggedInUser();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
          method: 'POST',
          headers: { "x-functions-key": `${hostHeader}` },
          body: JSON.stringify({ EntityRegistrationID: entityId }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setStateLogedInUser(data[0]?.emailId)
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    fetchUserDetails();
  }, []);
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${hostPath}/api/GetUserDetails`, {
        method: "POST",
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify({ EmailId: stateLogedInUser }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUserDetails(data[0]);
      setFormData({
        EntityName: data[0]?.entityName || "",
        EnityMailingAdderess: data[0]?.enityMailingAdderess || "",
        EnityMailingAdderess2: data[0]?.enityMailingAdderess2 || "",
        Country: data[0]?.country || "United States",
        CityId: data[0]?.cityId || "",
        Zipcode: data[0]?.zipcode || "",
        StateId: data[0]?.stateId || "",
        EmailId: data[0]?.emailId,
      });
      setProfileData({
        FirstName: data[0]?.firstName || "",
        LastName: data[0]?.lastName || "",
        EmailId: data[0]?.emailId || "",
        MobileNumber: data[0]?.mobileNumber || "",
        OfficeNumber: data[0]?.officeNumber || "",
        NewEmailId: "",
      });
      setOldMobilenum(data[0]?.mobileNumber);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };
  useEffect(() => {
    // Fetch user details from API
    fetchUserDetails();
  }, [stateLogedInUser, hostHeader]);

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `https://api.countrystatecity.in/v1/countries/US/states`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
            },
          }
        );
        var data = response.data.filter((country) =>
          /^[a-zA-Z0-9]+$/.test(country.iso2)
        );
        // Sort the states alphabetically by name
        data.sort((a, b) => a.name.localeCompare(b.name));
        setStates(data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (searchValue?.trim() !== "") {
      // Fetch suggestions from API
      axios
        .get(
          `https://atlas.microsoft.com/search/address/json?&subscription-key=iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`
        )
        .then((response) => {
          const results = response.data.results;
          if (results && results.length > 0) {
            // Extract suggestion labels from response and update state
            const suggestedLocations = results
              .filter((result) => result.address.countryCode === "US")
              .map((result) => result.address);
            setSuggestions(suggestedLocations);
          }
        })
        .catch((error) => {
          console.error("Error fetching location suggestions:", error);
        });
    } else {
      setSuggestions([]);
    }
  }, [searchValue]);

  const fetchCities = async (data) => {
    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/US/states/` +
        data +
        `/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
          },
        }
      );
      setCities(response.data);
      return response.data;
    } catch (error) {
      return [];
      console.error("Error fetching cities:", error);
    }
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;

    if (name === 'MobileNumber' || name === "OfficeNumber") {
      let formattedValue = value?.startsWith('+1') ? value : `+1${value}`;
      let digits = formattedValue.replace(/\D/g, '');
      if (digits.length > 11) {
        digits = digits.substring(0, 11);
      }
      formattedValue = `+1${digits.substring(1)}`;
      setProfileData({
        ...profileData,
        [name]: formattedValue,
      });
    } else {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    };
  }
    const validateFields = async (fields) => {
      const errors = {};
      const alphabetRegex = /^[A-Za-z\s]*$/;
      const alphaNumericRegex = /^[a-zA-Z0-9À-ÿ\s\-,.]*$/;
      const digitRegex = /^\d*$/;
      let emailexists = false;
      if (profileData?.EmailId && profileData?.EmailId !== userDetails?.emailId) {
        emailexists = await checkEmailExistence(profileData.EmailId);
      }

      if (!formData?.EntityName?.trim()) {
        errors.EntityName = "Entity Name is required";
      } else if (!alphaNumericRegex.test(formData.EntityName.trim())) {
        errors.EntityName = "Entity Name cannot contain special characters";
      }
      if (!formData?.EnityMailingAdderess?.trim()) {
        errors.EnityMailingAdderess = "Mailing Address is required";
      } else if (
        !/^[A-Za-z0-9\s,&]+$/.test(formData.EnityMailingAdderess.trim())
      ) {
        errors.EnityMailingAdderess =
          "Mailing Address cannot contain special characters";
      }
      if (!formData?.StateId?.trim()) {
        errors.StateId = "State is required";
      } else if (!/^[A-Za-z\s]+$/.test(formData.StateId.trim())) {
        errors.StateId = "State cannot contain special characters";
      }
      if (!formData?.CityId?.trim()) {
        errors.CityId = "City is required";
      } else if (!/^[A-Za-z\s]+$/.test(formData.CityId.trim())) {
        errors.CityId = "City cannot contain special characters";
      }
      if (!formData?.Zipcode?.trim()) {
        errors.Zipcode = "ZIP Code is required";
      } else if (!digitRegex.test(formData.Zipcode)) {
        errors.Zipcode = "ZIP Code must contain only digits";
      } else if (formData.Zipcode.length !== 5) {
        errors.Zipcode = "ZIP Code must be 5 digits long";
      }
      if (!profileData?.FirstName?.trim()) {
        errors.FirstName = "First Name is required";
      } else if (!alphabetRegex.test(profileData.FirstName)) {
        errors.FirstName = "First Name must contain only alphabets";
      }
      if (!profileData?.LastName?.trim()) {
        errors.LastName = "Last Name is required";
      } else if (!alphabetRegex.test(profileData.LastName)) {
        errors.LastName = "Last Name must contain only alphabets";
      }
      if (!profileData?.EmailId?.trim()) {
        errors.EmailId = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(profileData.EmailId)) {
        errors.EmailId = "Email is invalid";
      }
      else if (stateLogedInUser !== profileData?.EmailId && emailexists) {
        errors.EmailId = "Email has already been registered";
      }
      if (!profileData?.MobileNumber) {
        errors.MobileNumber = "Mobile Number is required";
      } else {
        const phoneNumber = profileData.MobileNumber.replace(/\D/g, "");
        if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
          errors.MobileNumber = "Mobile number is invalid";
        }
      }
      if (
        profileData?.OfficeNumber &&
        !/^\+1\d{10}$/.test(profileData.OfficeNumber)
      ) {
        errors.OfficeNumber = "Office Number is invalid";
      }
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    // The checkEmailExistence function
    async function checkEmailExistence(email) {
      setLoading(true);
      const emailid = { EmailId: email };
      const requestOptions = {
        method: "POST",
        headers: {
          "x-functions-key": `${hostHeader}`,
        },
        body: JSON.stringify(emailid),
      };

      try {
        const response = await axios.post(
          `${hostPath}/api/CheckEmailIdExist`,
          requestOptions.body,
          { headers: requestOptions.headers }
        );
        if (response.data === true) {
          setEmaiErr(true)
          return true;
        } else {
          setEmaiErr(false)
          return false;
        }
      } catch (error) {
        console.error("Error:", error);
        return false;
      } finally {
        setLoading(false);
      }
    }

    const handleSaveChanges = async (val) => {
      if (await validateFields(formData)) {
        setShowConfirmation(true);
        if (val === "profile") {
          setProfilechange(true);
        }
      }
    };

    const handleContinue = () => {
      setShowdialogue(false);
      setVerificationenabled(true);
      setRadiobuttonval(radiobuttonoption.current);
    };
    const handleBack = () => {
      setVerificationenabled(true);
    };
    const handleCancel = () => {
      setShowdialogue(false);
    };

    const handleradiobutton = (val) => {
      radiobuttonoption.current = val;
    };

    const confirmSaveChanges = async () => {
      if (profileChange) {
        confirmProfileChanges();
      } else {
        setShowConfirmation(false);
        const updatedFields = {};

        for (const key in formData) {
          if (formData[key] !== userDetails[key]) {
            updatedFields[key] = formData[key];
          }
        }
        updatedFields["EmailId"] = formData["EmailId"];
        try {
          const response = await fetch(`${hostPath}/api/updateuserdetails`, {
            method: "PUT",
            headers: {
              "x-functions-key": `${hostHeader}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFields),
          });
          const data = await response.json();
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          console.log("User details updated successfully:", data);
          fetchUserDetails(); // Trigger getUserDetails API call
        } catch (error) {
          console.error("Failed to update user details:", error);
        }
      }
    };
    const confirmProfileChanges = async () => {
      setShowConfirmation(false);
      for (const key in profileData) {
        personaldata[key] = profileData[key];
        if (
          profileData["EmailId"] !== userDetails["emailId"] &&
          profileData["MobileNumber"] !== userDetails["mobileNumber"]
        ) {
          setVerificationrequired("emailmobile");
          setShowdialogue(true);
        } else if (profileData["MobileNumber"] !== userDetails["mobileNumber"]) {
          setVerificationrequired("mobile");
          setShowdialogue(true);
        } else if (profileData["EmailId"] !== userDetails["emailId"]) {
          setVerificationrequired("email");
          setShowdialogue(true);
        }
        if (profileData["EmailId"] !== stateLogedInUser) {
          personaldata["NewEmailId"] = profileData["EmailId"];
          personaldata["EmailId"] = stateLogedInUser;
        }
        if (
          profileData["EmailId"] === userDetails["emailId"] &&
          profileData["MobileNumber"] === userDetails["mobileNumber"] &&
          Object.keys(personaldata).length === 6
        ) {
          updateProfile(personaldata);
        }
        setPersonalData(personaldata);
      }
    };
    const updateProfile = async (updatedFields) => {
      try {
        const response = await fetch(`${hostPath}/api/UpdateProfile`, {
          method: "PUT",
          headers: {
            "x-functions-key": `${hostHeader}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFields),
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return data;
      } catch (error) {
        console.error("Failed to update user details:", error);
        throw error;
      }
    };

    const cancelSaveChanges = () => {
      setShowConfirmation(false);
    };

    if (!userDetails) {
      return <div>Loading...</div>;
    }
    const validatePassword = (password) => {
      const requirements = {
        minLength: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        digit: /\d/.test(password),
        specialChar: /[!@#$%^&*]/.test(password),
      };
      setPasswordRequirements(requirements);
      const allRequirementsMet = Object.values(requirements).every(
        (requirement) => requirement
      );
      return allRequirementsMet;
    };
    const validateForm = () => {
      const errors = {};
      if (!CurrentPassword) {
        errors.CurrentPassword = "Please enter the current password";
      }
      if (!NewPassword) {
        errors.NewPassword = "Please enter the new password";
      } else if (!validatePassword(NewPassword)) {
        errors.NewPassword = "Passwords requirements are not satisified; ";
      } else if (NewPassword === CurrentPassword) {
        errors.NewPassword = "Cannot be same as the old password";
      }
      if (!ConfirmPassword) {
        errors.ConfirmPassword = "Please enter the confirm password";
      } else if (NewPassword != ConfirmPassword) {
        errors.ConfirmPassword = "Please ensure the passwords match";
      }
      setPasswordErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "Current Password") {
        setCurrentPassword(value);
      } else if (name === "NewPassword") {
        validatePassword(value);
        setNewPassword(value);
      } else if (name === "ConfirmPassword") {
        setConfirmPassword(value);
      }
      setPasswordErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const clearError = (fieldName) => {
      setErrors((prevErrors) => {
        return { ...prevErrors, [fieldName]: "" };
      });
    };

    const ChangePassword = async (e) => {
      e.preventDefault();
      if (validateForm()) {
        setShowDialog(true);
      }
    };

    const handleDialogConfirm = () => {
      setShowDialog(false);
      const loginUserData = {
        EmailId: stateLogedInUser,
        OldPassword: CurrentPassword,
        NewPassword: NewPassword,
      };
      const requestOptions = {
        method: "PUT",
        headers: { "x-functions-key": `${hostHeader}` },
        body: loginUserData,
      };
      axios
        .put(`${hostPath}/api/UpdatePassword`, requestOptions.body, {
          headers: requestOptions.headers,
        })
        .then((response) => {
          if (response.data.status === true) {
            navigate("/login");
          } else if (response.data.status === false) {
            setErrors({ CurrentPassword: "Old password is incorrect" });
          }
          console.log(" response.data", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const handleDialogCancel = () => {
      setShowDialog(false);
    };

    const handleSearchChange = (e) => {
      const { name, value } = e.target;
      if (name == "EnityMailingAdderess") {
        if (name) {
          errors.EnityMailingAdderess = "";
          setFormData({ ...formData, [name]: value });
        } else {
          errors.EnityMailingAdderess = "Mailing Address is required";
        }
      }
      setSearchValue(e.target.value);
    };

    const handleAddressSelect = (address) => {
      const selectedState = states.find(
        (state) =>
          state.name.toLowerCase() ===
          address?.countrySubdivisionName?.toLowerCase()
      );
      var citylist;
      var selectedCity;
      setMailingAddressZipCode(address?.postalCode);
      setMailingAddressCity(address?.municipality);
      errors.CityId = "";
      errors.Zipcode = "";
      setZipcodeWarning("");
      if (selectedState) {
        fetchCities(selectedState.iso2).then((citylist) => {
          selectedCity = citylist?.find(
            (city) =>
              city.name.toLowerCase() === address?.municipality?.toLowerCase()
          );
          setFormData((prevState) => ({
            ...prevState,
            CityId: selectedCity ? selectedCity.name : "",
          }));
        });
      }
      // Split the address by comma and select the first part
      const shortAddress = address?.freeformAddress.split(',')[0];
      setFormData((prevState) => ({
        ...prevState,
        EnityMailingAdderess: shortAddress, // Update with the short address
        Zipcode: address?.postalCode,
        StateId: selectedState ? selectedState.iso2 : "",
      }));
      setSearchValue("");
      setSuggestions([]);
    };
    console.log("suggestions", suggestions)
    return (
      <div>
        {!verificationenabled ? (
          <div className="container_fluid">
            <Navbar mapvisible={mapvisible} />
            <div className="container">
              <h2 className="settingheader">Settings</h2>
              <div className="row">
                <div className="col-3">
                  <div className="Entityinfo">
                    <h6 className="personinfoheader">PERSONAL INFORMATION</h6>
                    <span
                      className={`sideHeader ${activeSection === "entity" ? "active" : ""
                        }`}
                      onClick={() => handleSectionChange("entity")}
                    >
                      Entity Information
                    </span>
                    <br />
                    <span
                      className={`sideHeader ${activeSection === "profile" ? "active" : ""
                        }`}
                      onClick={() => handleSectionChange("profile")}
                    >
                      Profile Information
                    </span>
                    <br />
                    <span
                      className={`sideHeader ${activeSection === "password" ? "active" : ""
                        }`}
                      onClick={() => handleSectionChange("password")}
                    >
                      Change Password
                    </span>
                    <br />
                    <span
                      className={`sideHeader ${activeSection === "pricing" ? "active" : ""
                        }`}
                      onClick={() => handleSectionChange("pricing")}
                    >
                      Pricing Page
                    </span>
                  </div>
                </div>
                <div className="col-9">
                  {activeSection === "entity" && (
                    <div className="entity-info-container">
                      <h2 style={{ textAlign: "left" }}>Entity Information</h2>
                      <hr />
                      <form className="entity-form">
                        <div className="form-entity">
                          <label>Entity Name*</label>
                          <input
                            type="text"
                            name="EntityName"
                            maxLength={50}
                            value={formData?.EntityName}
                            onChange={handleInputChange}
                          />
                          {errors.EntityName && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.EntityName}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>Country*</label>
                          <input
                            type="text"
                            name="Country"
                            value={formData?.Country}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-entity">
                          <label>Entity Mailing Address*</label>
                          <input
                            type="text"
                            className={`form-control ${formData.EnityMailingAdderess ? "input-active" : ""
                              }`}
                            id="password"
                            maxLength={100}
                            placeholder="Enter address "
                            name="EnityMailingAdderess"
                            value={formData.EnityMailingAdderess}
                            onChange={(e) => {
                              handleSearchChange(e);
                            }}
                          />
                          {suggestions.length > 0 && (
                            <ul className="search-results Addrees-search-results">
                              {suggestions.map((result) => (
                                <li onClick={() => handleAddressSelect(result)}>
                                  {result.freeformAddress}
                                </li>
                              ))}
                            </ul>
                          )}
                          {errors.EnityMailingAdderess && (
                            <div className="error-message mandatorymark">
                              {errors.EnityMailingAdderess}
                            </div>
                          )}
                        </div>

                        <div className='form-entity'>
                          <label>Entity Mailing Address 2</label>
                          <input
                            type="text"
                            name="EnityMailingAdderess2"
                            value={formData?.EnityMailingAdderess2}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-entity">
                          <label>ZIP Code*</label>
                          <input
                            type="text"
                            name="Zipcode"
                            maxLength={5}
                            value={formData?.Zipcode}
                            onChange={handleInputChange}
                          />
                          {errors.Zipcode && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.Zipcode}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>State*</label>
                          <input
                            type="text"
                            name="StateId"
                            maxLength={30}
                            value={formData?.StateId}
                            onChange={handleInputChange}
                          />
                          {errors.StateId && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.StateId}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>City*</label>
                          <input
                            type="text"
                            name="CityId"
                            maxLength={30}
                            value={formData?.CityId}
                            onChange={handleInputChange}
                          />
                          {errors.CityId && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.CityId}
                            </p>
                          )}
                        </div>
                        <div className="form-entity"></div>
                        <div className="form-entity">
                          <button
                            type="button"
                            className="save-changes-button"
                            onClick={handleSaveChanges}
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  {activeSection === "profile" && (
                    <div className="entity-info-container">
                      <h2 style={{ textAlign: "left" }}>Profile Information</h2>
                      <hr />
                      <form className="entity-form">
                        <div className="form-entity">
                          <label>First Name*</label>
                          <input
                            type="text"
                            name="FirstName"
                            value={profileData?.FirstName}
                            onChange={handleProfileChange}
                          />
                          {errors.FirstName && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.FirstName}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>Last Name*</label>
                          <input
                            type="text"
                            name="LastName"
                            value={profileData?.LastName}
                            onChange={handleProfileChange}
                          />
                          {errors.LastName && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.LastName}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>Email*</label>
                          <input
                            type="email"
                            name="EmailId"
                            value={profileData?.EmailId}
                            onChange={handleProfileChange}
                          />
                          {errors.EmailId && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.EmailId}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>Mobile Number*</label>
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            name="MobileNumber"
                            maxLength={15}
                            value={profileData?.MobileNumber}
                            id="MobileNumber"
                            placeholder="Enter phone number"
                            defaultCountry='US'
                            countries={['US']}
                            onChange={(value) => handleProfileChange({ target: { name: "MobileNumber", value } })}
                            className="CustomPhoneInput" />
                          {errors.MobileNumber && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.MobileNumber}
                            </p>
                          )}
                        </div>
                        <div className="form-entity">
                          <label>Entity Office Number*</label>
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            name="OfficeNumber"
                            maxLength={15}
                            value={profileData?.OfficeNumber}
                            id="OfficeNumber"
                            countries={['US']}
                            placeholder="Enter office number"
                            defaultCountry='US'
                            className="CustomPhoneInput"
                            onChange={(value) => handleProfileChange({ target: { name: "OfficeNumber", value } })}
                          />
                          {errors.OfficeNumber && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {errors.OfficeNumber}
                            </p>
                          )}
                        </div>
                        <div className="form-entity"></div>
                        <div className="form-entity">
                          <button
                            type="button"
                            className="save-changes-button"
                            onClick={() => handleSaveChanges("profile")}
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  {activeSection === "password" && (
                    <div className="entity-info-container">
                      <h2 style={{ textAlign: "left" }}>Change Password</h2>
                      <hr />
                      <form className="change-password-form">
                        <div className="form-entity Password-Setting">
                          <label>Current Password*</label>
                          <style>
                            {`
                                input::-ms-reveal {
                                display: none;
                                }
                                `}
                          </style>
                          <div className="password-input-container">
                            <input
                              type={currentPasswordVisible ? "text" : "password"}
                              defaultValue={CurrentPassword}
                              placeholder="Enter Current Password"
                              name="Current Password"
                              onChange={handleChange}
                            />
                            <button
                              type="button"
                              onClick={toggleCurrentPasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={currentPasswordVisible ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                          {passworderrors.CurrentPassword && (
                            <p style={{ textAlign: "left", color: "red" }}>
                              {" "}
                              {passworderrors.CurrentPassword}
                            </p>
                          )}
                        </div>
                        <div className="form-entity Password-Setting">
                          <label>New Password*</label>
                          <style>
                            {`
                                input::-ms-reveal {
                                display: none;
                                }
                                `}
                          </style>
                          <div className="password-input-container">
                            <input
                              type={newPasswordVisible ? "text" : "password"}
                              placeholder="Enter New Password"
                              onChange={handleChange}
                              name="NewPassword"
                              defaultValue={NewPassword}
                            />
                            <button
                              type="button"
                              onClick={toggleNewPasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={newPasswordVisible ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                          {passworderrors.NewPassword && (
                            <div
                              className="error-message mandatorymark"
                              style={{ textAlign: "left" }}
                            >
                              {passworderrors.NewPassword}
                            </div>
                          )}
                        </div>
                        <p className="StrongPassword-H">
                          To ensure your account security, please create a strong
                          password meeting these requirements:
                        </p>
                        <ul
                          style={{
                            listStyleType: "none",
                            paddingLeft: 0,
                            paddingTop: 10,
                          }}
                        >
                          {" "}
                          <li
                            style={{
                              display: "flex",
                              marginBottom: "5px",
                              alignItems: "center",
                              color: passwordRequirements.minLength
                                ? "green"
                                : "red",
                            }}
                          >
                            {passwordRequirements.minLength ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <HighlightOffIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "red",
                                }}
                              />
                            )}
                            At least 8 characters
                          </li>
                          <li
                            style={{
                              display: "flex",
                              marginBottom: "5px",
                              alignItems: "center",
                              color: passwordRequirements.uppercase
                                ? "green"
                                : "red",
                            }}
                            className={
                              passwordRequirements.uppercase
                                ? "password-requirements1"
                                : "password-requirements"
                            }
                          >
                            {" "}
                            {passwordRequirements.uppercase ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <HighlightOffIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "red",
                                }}
                              />
                            )}{" "}
                            Includes at least one uppercase letter (A-Z)
                          </li>
                          <li
                            style={{
                              display: "flex",
                              marginBottom: "5px",
                              alignItems: "center",
                              color: passwordRequirements.lowercase
                                ? "green"
                                : "red",
                            }}
                            className={
                              passwordRequirements.lowercase
                                ? "password-requirements1"
                                : "password-requirements"
                            }
                          >
                            {" "}
                            {passwordRequirements.lowercase ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <HighlightOffIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "red",
                                }}
                              />
                            )}{" "}
                            Includes at least one lowercase letter (a-z)
                          </li>
                          <li
                            style={{
                              display: "flex",
                              marginBottom: "5px",
                              alignItems: "center",
                              color: passwordRequirements.digit ? "green" : "red",
                            }}
                            className={
                              passwordRequirements.digit
                                ? "password-requirements1"
                                : "password-requirements"
                            }
                          >
                            {" "}
                            {passwordRequirements.digit ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <HighlightOffIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "red",
                                }}
                              />
                            )}
                            Includes at least one digit (0-9)
                          </li>
                          <li
                            style={{
                              display: "flex",
                              marginBottom: "5px",
                              alignItems: "center",
                              color: passwordRequirements.specialChar
                                ? "green"
                                : "red",
                            }}
                            className={
                              passwordRequirements.specialChar
                                ? "password-requirements1"
                                : "password-requirements"
                            }
                          >
                            {" "}
                            {passwordRequirements.specialChar ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "green",
                                }}
                              />
                            ) : (
                              <HighlightOffIcon
                                style={{
                                  fontSize: "16px",
                                  marginRight: "8px",
                                  color: "red",
                                }}
                              />
                            )}
                            Includes at least one special character (!, @, #, $,
                            %, ^, &, *)
                          </li>
                        </ul>
                        <div className="form-entity Password-Setting">
                          <label>Confirm Password*</label>
                          <style>
                            {`
                                input::-ms-reveal {
                                display: none;
                                }
                                `}
                          </style>
                          <div className="password-input-container">
                            <input
                              type={confirmPasswordVisible ? "text" : "password"}
                              placeholder="Enter Confirm Password"
                              name="ConfirmPassword"
                              onChange={handleChange}
                              defaultValue={ConfirmPassword}
                            />
                            <button
                              type="button"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={confirmPasswordVisible ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                          {passworderrors.ConfirmPassword && (
                            <div
                              className="error-message mandatorymark"
                              style={{ textAlign: "left" }}
                            >
                              {passworderrors.ConfirmPassword}
                            </div>
                          )}
                        </div>
                        <div className="form-entity Password-Setting">
                          <button
                            type="submit"
                            className="change-password-button"
                            onClick={ChangePassword}
                          >
                            Change Password
                          </button>
                        </div>
                      </form>
                      {showDialog && (
                        <div className="dialog-overlay">
                          <div className="dialog-box">
                            <h2 style={{ alignItems: "left" }}>Change Password</h2>
                            <p>Are you sure you want to change your password? You will be logged out and would need to log in with your new password to continue</p>
                            <button onClick={handleDialogConfirm}>OK</button>
                            <button onClick={handleDialogCancel}>Cancel</button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {activeSection === "pricing" && (
                    <div className="pricing-info-container">
                      <h2>Parking Fees Charged</h2>
                      <h5>Below are the current fees charged per parking space per hour</h5>
                      <p>Note: Billing is incurred/Invoiced per minute</p>
                      <h6>PARKING CHARGED TO AUTONOMOUS VEHICLE/ROBOTAXI</h6>
                      <ul>
                        <li>
                          <span className="description">
                          $0.50 Price Per Hour - 7pm-7am Weekdays (Mon-Thurs)
                          <li>
                          Percentage of parking fees you receive: 70%
                          </li>
                          </span>
                        </li>
                        <li>
                          <span className="description">
                          $0.75    Price Per Hour - 7am-7pm- Weekdays (Mon-Fri)
                          <li>
                          Percentage of parking fees you receive: 70%
                          </li>
                          </span>
                          {/* <span className="price">$0.75</span> */}
                        </li>
                        <li>
                          <span className="description">
                          $0.25    Price Per Hour - Weekends (7pm Fri. - 7am Mon.)
                          <li>
                          Percentage of parking fees you receive: 70%
                          </li>
                          </span>
                          {/* <span className="price">$0.25</span> */}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showConfirmation && (
              <div className="confirmation-popup">
                <div className="confirmation-popup-content">
                  <p>Are you sure you want to save the changes?</p>
                  <button className="Confirm" onClick={confirmSaveChanges}>
                    Confirm
                  </button>
                  <button className="Cancel" onClick={cancelSaveChanges}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {!showdialogue && verificationenabled && (
              <SignUP
                updateProfile={updateProfile}
                personaldata={personalData}
                entityData={formData}
                verificationRequired={verificationRequired}
                stateLogedInUser={stateLogedInUser}
                radiobuttonval={radiobuttonval}
                setRadiobuttonval={setRadiobuttonval}
                oldNumber={oldMobilenum}
                handleBack={handleBack}
              />
            )}
          </div>
        )}
        <BootstrapDialog
          //onClose={handleContinue}
          classes={{ paper: "custom-paper-class" }}
          aria-labelledby="customized-dialog-title"
          dividers
          sx={{
            textAlign: "start",
            overflowY: "auto",
            padding: "5rem 5rem",
            borderRadius: "5px",
          }}
          open={showdialogue}
        >
          <DialogTitle
            dividers
            sx={{
              textAlign: "start",
              color: "rgba(51, 51, 51, 1)",
              fontSize: "24px",
              fontWeight: "700",
            }}
            id="customized-dialog-title"
          >
            Verify that its you..
          </DialogTitle>
          <DialogContent style={{ border: "none" }}>
            <Typography gutterBottom>
              <span>
                Confirm your identity by selecting your current email or phone.
                Will send the One Time Verification Code.
                This ensures your account stays secure.
              </span>
            </Typography>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onClick={(e) => handleradiobutton(e.target.value)}
              >
                <FormControlLabel
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  value="phone"
                  control={<Radio />}
                  label="Phone"
                  className="radiolabel"
                />
                <FormControlLabel
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  value="email"
                  control={<Radio />}
                  label="Email"
                  className="radiolabel"
                />
              </RadioGroup>
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              className='mb-3'
              autoFocus
              onClick={handleContinue}
              classes={{ root: "Secondary" }}
              sx={{
                width: "150px",
                height: "50px",
                backgroundColor: "#6BCD55",
                color: "#000",
                fontFamily: "Montserrat",
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: 700,
                "&:hover": { backgroundColor: "#5aa949" },
              }}
            >
              <span>Continue</span>
            </Button>
            <Button
              className="mb-3"
              autoFocus
              classes={{ root: "Secondary" }}
              onClick={handleCancel}
              sx={{
                width: "150px",
                height: "50px",
                backgroundColor: "#6BCD55",
                color: "#000",
                fontFamily: "Montserrat",
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: 700,
                backgroundColor: "lightgrey",
                "&:hover": { backgroundColor: "#A9A9A9" },
              }}
            >
              <span>Cancel</span>
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    )
  }
  export default SettingEntityInfo;
