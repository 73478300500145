// EmployeeRegistrationForm.js
import React, { useState } from "react";
import RobotaxiLogin from '../../Images/LoginImage.png';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';

const GeoParkingUserRegistrationForm = () => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Domain] = useState("GeoParking.com");
  const [Department, setDepartment] = useState("");
  const [TermsCondition, setTermsAndCondition] = useState(false);
  const [errors, setErrors] = useState({});

  // Regular expression to validate email format specifically for @geoparking.com
  const emailPattern = /^[a-zA-Z0-9._%+-]+@geoparking\.com$/;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    switch (name) {
      case "FirstName":
        setFirstName(value);
        if (value.trim() === "") setErrors({ ...errors, firstName: "First name is required!" });
        else setErrors({ ...errors, firstName: "" });
        break;

      case "LastName":
        setLastName(value);
        if (value.trim() === "") setErrors({ ...errors, lastName: "Last name is required!" });
        else setErrors({ ...errors, lastName: "" });
        break;

      case "Email":
        setEmail(value);
        if (!emailPattern.test(value)) {
          setErrors({ ...errors, email: "Email must be in the format: name@geoparking.com!" });
        } else {
          setErrors({ ...errors, email: "" });
        }
        break;

      case "Phone":
        setPhone(value);
        const phoneNumber = value?.replace(/\D/g, "");
        if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
          setErrors({ ...errors, phone: "Mobile number is invalid" });
        } else {
          setErrors({ ...errors, phone: "" });
        }
        break;

      case "department":
        setDepartment(value);
        if (value.trim() === "") setErrors({ ...errors, department: "Department is required!" });
        else setErrors({ ...errors, department: "" });
        break;

      case "T&C":
        setTermsAndCondition(checked);
        if (checked) {
          setErrors({ ...errors, TermsCondition: "" });
        } else {
          setErrors({ ...errors, TermsCondition: "You must agree to the terms and conditions!" });
        }
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!FirstName.trim()) validationErrors.firstName = "First name is required!";
    if (!LastName.trim()) validationErrors.lastName = "Last name is required!";
    if (!Email.trim()) validationErrors.email = "Email is required!";
    else if (!emailPattern.test(Email)) validationErrors.email = "Email must be in the format: name@geoparking.com!";
    if (!Phone.trim()) validationErrors.phone = "Mobile number is required!";
    else if (Phone) {
      const phoneNumber = Phone?.replace(/\D/g, "");
      if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
        validationErrors.phone = "Mobile number is invalid";
      }
    }
    if (!Department.trim()) validationErrors.department = "Department is required!";
    if (TermsCondition === false) validationErrors.TermsCondition = "Agree to the Terms & Conditions!";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const registerUserData = {
        "FirstName": FirstName,
        "LastName": LastName,
        "CompanyEmail": Email,
        "ContactNumber": Phone,
        "Department": Department,
      };
      const requestOptions = {
        method: 'POST',
        headers: { "x-functions-key": `${hostHeader}` },
        body: JSON.stringify(registerUserData)
      };
      axios.post(`${hostPath}/api/RegisterInternalEmployee`, requestOptions.body, { headers: requestOptions.headers })
        .then(response => {
          if (response.data[0]) {
            console.log("Response data:", response.data[0]);
          }
          console.log("Form Submitted", { FirstName, LastName, Email, Phone, Domain, Department });
        })
        .catch(error => {
          console.error('Error:', error);
          setErrors({});
        });
    }
  };

  const handleCancel = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setDepartment("");
    setErrors({});
  };

  return (
    <div className="registration-container textleft">
      <div className="image-section">
        <img src={RobotaxiLogin} alt="Login" style={{ width: "100%", height: "100vh" }} />
      </div>
      <div className="form-section">
        <h1 style={{ fontWeight: "700" }}>Join GeoParking Sales</h1>
        <p>Unlock Your Potential in Smart Parking Solutions</p>
        <form onSubmit={handleSubmit}>
          <div className="FirstName mb-4 textleft">
            <label htmlFor="FirstName" className='FirstName loginlabel'>First Name</label>
            <span className='mandatorymark loginlabel'>*</span>
            <input
              type="text"
              id="FirstName"
              name="FirstName"
              placeholder="Enter First Name"
              value={FirstName}
              onChange={handleChange}
            />
            <div className="validation-error">
              {errors.firstName && <span className="error">{errors.firstName}</span>}
            </div>
          </div>
          <div className="LastName mb-4 textleft">
            <label htmlFor="LastName" className='LastName loginlabel'>Last Name</label>
            <span className='mandatorymark loginlabel'>*</span>
            <input
              type="text"
              name="LastName"
              id="LastName"
              placeholder="Enter Last Name"
              value={LastName}
              onChange={handleChange}
            />
            <div className="validation-error">
              {errors.lastName && <span className="error">{errors.lastName}</span>}
            </div>
          </div>
          <div className="Email mb-4 textleft">
            <label htmlFor="Email" className='Email loginlabel'>Email Address</label>
            <span className='mandatorymark loginlabel'>*</span>
            <input
              type="email"
              name="Email"
              id="Email"
              placeholder="Enter Email Address"
              value={Email}
              onChange={handleChange}
            />
            <div className="validation-error">
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>
          <div className="Phone mb-4 textleft">
            <label htmlFor="Phone" className='Phone loginlabel'>Phone Number</label>
            <span className='mandatorymark loginlabel'>*</span>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              name="Phone"
              maxLength={15}
              value={Phone}
              id="Phone"
              placeholder="Enter Your Contact Number"
              defaultCountry="US"
              countries={["US"]}
              onChange={(value) => handleChange({ target: { name: "Phone", value } })}
              className="Phone-Internal-Input"
            />
            <div className="validation-error">
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
          </div>
          <div className="Department mb-4 textleft">
            <label htmlFor="Department" className="Department loginlabel">Select the Department</label>
            <span className="mandatorymark loginlabel">*</span>
            <select
              name="department"
              value={Department}
              id="Department"
              onChange={handleChange}
            >
              <option value="">Select Department</option>
              <option value="Inside Sales">Inside Sales</option>
              <option value="Outside Sales">Outside Sales</option>
              <option value="Admin">Admin</option>
              <option value="CXO">CXO</option>
            </select>
            <div className="validation-error">
              {errors.department && <span className="error">{errors.department}</span>}
            </div>
          </div>
          <div className="textleft d-flex mt-4 mb-4 checkboxform">
            <input
              className="checkbox"
              type="checkbox"
              name="T&C"
              checked={TermsCondition}
              onChange={handleChange}
            />
            <span className="TermsCondition">I agree to the terms and conditions</span>
          </div>

          <div className="validation-error mb-3">
            {errors.TermsCondition && <span className="error">{errors.TermsCondition}</span>}
          </div>
          <button type="submit" className="register-button mt-3">Register</button>
          <p className="login-link">
            Already a member? <a href="#">Log in</a> here.
          </p>
        </form>
      </div>
    </div>
  );
};

export default GeoParkingUserRegistrationForm;
