import React, { useState } from "react";
import CalendarComponent from "./CalendarComponent.jsx";
import AvailabilityModal from "./AvailabilityModal.jsx";
import "./Scheduler.css";
import { addDays, addWeeks, addMonths, format } from "date-fns";

const Scheduler = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  

  // Function to handle adding a new event
  const handleAddEvent = (start) => {
    console.log("Adding new event at start time:", start);
    setSelectedEvent({
      id: new Date().getTime(),
      title: "",
      start: start,
      end: new Date(start).setHours(start.getHours() + 1),
      allDay: false,
      recurrence: null,
      recurrenceId: new Date().getTime(),
      selectedDays: [],
      endType: "never", // Set default to 'never'
      endDateOn: null, // End date should be null initially
    });
    setIsEditing(false);
    setShowModal(true);
  };

  // Function to handle editing an existing event
  const handleSelectEvent = (event) => {
    console.log("Editing selected event:", event);
    setSelectedEvent(event);
    setIsEditing(true);
    setShowModal(true);
  };

  // Function to handle saving an event
  const handleSave = (newEvent, recurrenceChanged) => {
    console.log("Saving event with recurrence settings", newEvent);

    // Remove old events if recurrence has changed or if we are editing an event
    if (isEditing) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) => event.recurrenceId !== selectedEvent.recurrenceId
        )
      );
      console.log("Deleted old series:", selectedEvent.recurrenceId);
    }

    // Ensure if recurrence is set to 'never', endDateOn is 1 year from the start date
    if (newEvent.endType === "never" && !newEvent.endDateOn) {
      const oneYearLater = new Date(newEvent.start);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      newEvent.endDateOn = oneYearLater;
    }

    // Check if newEvent contains selectedDays for custom recurrence
    if (newEvent.recurrence === "custom" && newEvent.selectedDays.length > 0) {
      console.log("Custom recurrence days:", newEvent.selectedDays);
    }

    // Generate new recurrence events or save the single event
    if (newEvent.recurrence) {
      const recurringEvents = generateRecurringEvents(newEvent);
      console.log("Generated recurring events:", recurringEvents);

      // Merge new events with previous events
      setEvents((prevEvents) => [...prevEvents, ...recurringEvents]);
    } else {
      // Handle single (non-recurring) events
      if (isEditing) {
        setEvents(
          events.map((event) =>
            event.id === selectedEvent.id
              ? { ...newEvent, id: selectedEvent.id }
              : event
          )
        );
      } else {
        newEvent.id = new Date().getTime();
        setEvents([...events, newEvent]);
      }
    }

    // Handle closing the modal after save
    handleCloseModal();
  };

  // Daily recurrence generation
  const generateCustomRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const selectedDays =
      event.selectedDays && event.selectedDays.length > 0
        ? event.selectedDays
        : [format(startDate, "EEE")]; // Use selected days or fallback to start day

    const recurrenceInterval = parseInt(event.customRecurrenceCount, 10) || 1;

    console.log("Initial Date: ", currentDate);
    console.log("Recurrence Interval:", recurrenceInterval);
    console.log("Selected Days (for weekly):", selectedDays);
    console.log("End Date On:", endDateOn);

    if (isNaN(currentDate.getTime())) {
      console.error("Invalid startDate:", startDate);
      return events;
    }

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      // Process each selected day in the week
      for (const day of selectedDays) {
        const currentDayIndex = currentDate.getDay();
        const targetDayIndex = [
          "Sun",
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
        ].indexOf(day);

        // Calculate the difference between current day and target day
        const dayDifference = targetDayIndex - currentDayIndex;
        const eventDate = new Date(currentDate);
        eventDate.setDate(currentDate.getDate() + dayDifference);

        if (
          eventDate >= new Date(startDate) &&
          (!endDateOn || eventDate <= new Date(endDateOn))
        ) {
          console.log("Processing custom weekly event for date:", eventDate);
          events.push({
            ...event,
            start: new Date(eventDate),
            end: new Date(eventDate.getTime() + duration),
          });

          console.log("Adding custom weekly event for date:", eventDate);
          i++;
        }
      }

      // Move to the next recurrence interval (next week)
      currentDate = addWeeks(currentDate, recurrenceInterval);
      console.log(
        `Next Date based on Weekly Interval of ${recurrenceInterval} weeks:`,
        currentDate
      );

      if (isNaN(currentDate.getTime())) {
        console.error(
          "Invalid currentDate during recurrence generation:",
          currentDate
        );
        break;
      }
    }

    console.log("Generated custom recurring events:", events);
    return events;
  };

  const generateWeeklyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) => {
    console.log("Generating weekly recurring events:", event);
    const events = [];
    let currentDate = new Date(startDate); // Starting date
    let i = 0;

    const selectedDays = event.selectedDays.length
      ? event.selectedDays
      : [format(currentDate, "EEE")];

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log(
        "Checking for valid day:",
        format(currentDate, "EEE"),
        "with selected days:",
        selectedDays
      );
      if (selectedDays.includes(format(currentDate, "EEE"))) {
        console.log("Adding event for date:", currentDate);
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }

      currentDate = calculateNextWeeklyDate(currentDate, interval); // Use helper for next weekly date
    }

    return events;
  };

  // Monthly recurrence generation
  const generateMonthlyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log("Generating monthly recurring events:", event);
    const events = [];
    let currentDate = new Date(startDate); // Starting date
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log("Adding event for date:", currentDate);
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      currentDate = calculateNextMonthlyDate(
        currentDate,
        event.customRecurrenceCount || 1
      ); // Use helper for next monthly date
      i++;
    }

    return events;
  };

  const generateDailyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const recurrenceInterval = event.customRecurrenceCount || 1;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      i++;
      currentDate = addDays(currentDate, recurrenceInterval);
    }

    return events;
  };

  // Weekday Recurrence (Monday to Friday)
  const generateWeekdayRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log("Generating weekday events");
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // Monday to Friday
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }
      currentDate = addDays(currentDate, 1); // Move to next day
    }

    return events;
  };

  // Weekend Recurrence (Saturday & Sunday)
  const generateWeekendRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log("Generating weekend events");
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // Saturday or Sunday
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }
      currentDate = addDays(currentDate, 1); // Move to next day
    }

    return events;
  };

  // Helper methods
  const calculateNextWeeklyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + interval * 7);
    return nextDate;
  };

  const calculateNextMonthlyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setMonth(nextDate.getMonth() + interval);
    return nextDate;
  };

  // Function to generate recurring events
  const generateRecurringEvents = (event) => {
    console.log("Event recurrence property value:", event.recurrence);

    let events = [];

    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const duration = endDate - startDate;

    let occurrences = event.endAfter || null;
    let endDateOn = event.endDateOn ? new Date(event.endDateOn) : null;

    // If the recurrence is set to never, impose a 1-year limit
    if (!occurrences && !endDateOn && event.endType === "never") {
      const oneYearLater = new Date(startDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      endDateOn = oneYearLater;
    }

    console.log("Generating events for recurrence:", event.recurrence, {
      startDate,
      duration,
      occurrences,
      endDateOn,
    });

    switch (event.recurrence) {
      case "daily":
        events = generateDailyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case "weekly":
        events = generateWeeklyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case "monthly":
        events = generateMonthlyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case "custom":
        events = generateCustomRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case "bi-weekly":
        console.log(
          "this is the event.recurrence property for bi-weekly",
          event.recurrence
        );
        events = generateWeeklyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn,
          2
        ); // Bi-weekly treated as 2 weeks interval
        break;
      case "weekdays":
        console.log(
          "this is the event.recurrence property for weekdays",
          event.recurrence
        );
        events = generateWeekdayRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case "weekends":
        console.log(
          "this is the event.recurrence property for weekends",
          event.recurrence
        );
        events = generateWeekendRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      default:
        console.warn("Unknown recurrence type:", event.recurrence);
        break;
    }

    return events.map((e) => ({
      ...e,
      recurrenceId: event.recurrenceId || new Date().getTime(),
    }));
  };

  // Recurrence generation functions (Weekly, Monthly, Custom) remain unchanged.
  // ...

  // Function to delete an event
  const handleDelete = (event, deleteSeries) => {
    console.log(
      "Deleting event:",
      event,
      "Delete entire series:",
      deleteSeries
    );
    if (deleteSeries && event.recurrence) {
      setEvents((prevEvents) =>
        prevEvents.filter((e) => e.recurrenceId !== event.recurrenceId)
      );
    } else {
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
    }
    handleCloseModal();
  };

  // Function to close the modal
  const handleCloseModal = () => {
    console.log("Closing modal");
    setShowModal(false);
    setSelectedEvent(null);
    setIsEditing(false);
    document.activeElement.blur();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <div className="Scheduler">
      <CalendarComponent
        events={events}
        onSelectEvent={handleSelectEvent}
        onAddEvent={handleAddEvent}
        onOpenModal={handleOpenModal}
      />
      <AvailabilityModal
        show={showModal}
        onClose={handleCloseModal}
        onSave={handleSave}
        onDelete={handleDelete}
        isEditing={isEditing}
        eventDetails={
          selectedEvent || {
            title: "",
            start: "",
            end: "",
            recurrence: "",
            allDay: false,
          }
        }
      />
    </div>
  );
};

export default Scheduler;
